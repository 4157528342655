import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";

export default class PageTemplate extends React.Component {
  render() {
    const {
      data: { contentfulPage: page },
      location
    } = this.props;
    const pageURL = config.siteUrl + location.pathname;

    return (
      <Layout location={location}>
        <Helmet>
          <title>{`${page.title} | ${config.siteTitle}`}</title>
          <meta name="description" content={page.description} />
          <meta property="og:url" content={pageURL} />
          <meta property="og:title" content={page.title} />
          <meta property="og:description" content={page.description} />
          <meta name="twitter:title" content={page.title} />
          <meta name="twitter:description" content={page.description} />
        </Helmet>
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-7-fullhd is-8-widescreen is-9-tablet">
                <h1 className="title is-size-3">{page.title}</h1>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: page.body.childMarkdownRemark.html
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      description
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
